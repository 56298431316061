.App {
  text-align: center;
  /* max-width: 1000px;   */
  margin: 0 auto;
  
}
.dark {
  background-color: #282c34;
  color: white;
}

.light {
  background-color: #d3c9d3;
  color: black;
}

.navStyle {

  color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.darkText {
  color: white !important;
} 

.lightText {
  color: black !important;
}

.innerBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  color: white;
  padding: 40px;
  padding-top: 100px;

}

.footer {

  left: 0;
  bottom: 0;

  min-height: 200px;
  background-color: #383040;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: auto;
  padding: 20px;
}

.g-link {
  color: #b7b7b7;
  text-decoration: none;
}
